<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Vamos responder as perguntas mais frequentes
          </h2>
          <b-card-text class="mb-2">
            ou escolha uma categoria para ver as perguntas
          </b-card-text>
          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Procurar no faq ..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        v-model="tabIndexCategory"
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
      >

        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName) in faqData"
          :key="categoryName"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              size="18"
              class="mr-1"
              :icon="categoryObj.icon"
            />
            <router-link
              tag="span"
              class="font-weight-bold"
              :to="'/spot/faq/'+sanitizeTitle(categoryObj.titulo)"
            >
              {{ categoryObj.titulo }}
            </router-link>
          </template>

          <faq-category :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

  </div>
</template>

<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import FaqCategory from './FaqCategory.vue'
import faqStoreModule from './faqStoreModule'

const FAQ_SPOT_STORE_MODULE_NAME = 'faq-spot'

export default {
  components: {
    BForm,
    BCard,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqCategory,
  },

  mixins: [utilsMixin],

  data() {
    return {
      faqSearchQuery: '',
      faqDataOrigem: {},
      faqData: {},
      tabIndexCategory: 0,
    }
  },

  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.filterData()
      },
    },
  },

  created() {
    if (!store.hasModule(FAQ_SPOT_STORE_MODULE_NAME)) store.registerModule(FAQ_SPOT_STORE_MODULE_NAME, faqStoreModule)
    this.fetchData()
  },

  beforeDestroy() {
    if (store.hasModule(FAQ_SPOT_STORE_MODULE_NAME)) store.unregisterModule(FAQ_SPOT_STORE_MODULE_NAME)
  },

  mounted() {
    if (this.$route.params.slugQuest) {
      this.scrollToElement(this.$el.getElementsByClassName(`quest-${this.$route.params.slugQuest}`)[0])
    }
  },

  methods: {

    filterData() {
      const filteredData = {}
      const queryLowered = this.faqSearchQuery.toLowerCase()
      Object.entries(this.faqDataOrigem).forEach(entry => {
        const [categoryName, categoryObj] = entry
        // eslint-disable-next-line arrow-body-style
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
          return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
          filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
        }
      })
      this.faqData = filteredData
    },

    fetchData() {
      store
        .dispatch('faq-spot/getFaqCategorys')
        .then(response => {
          this.faqDataOrigem = response.data
          this.faqData = response.data
          this.tabIndexCategory = Object.entries(this.faqDataOrigem).findIndex(tab => this.sanitizeTitle(tab[1].titulo) === this.$route.params.slugCategory)
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
