<template>
  <div v-if="options">
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-primary"
        class="mr-1"
      >
        <feather-icon
          :icon="options.icon"
          size="20"
        />

      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ options.titulo }}
        </h4>
        <span>{{ options.subtitulo }}</span>
      </div>
    </div>

    <div class="mt-2 collapse-margin">
      <div
        v-for="( data,index) in options.faqList"
        :key="index"
        class="card"
      >

        <router-link
          tag="div"
          class="card-header"
          :to="'/spot/faq/'+sanitizeTitle(options.titulo)+'/'+data.tituloNormalizado"
        >
          <span class="lead collapse-title">{{ data.pergunta }}</span>
          <feather-icon
            icon="ArrowRightCircleIcon"
            size="24"
          />
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import utilsMixin from '@/mixins/utilsMixin'

export default {

  components: {
    BAvatar,
  },

  mixins: [utilsMixin],

  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },

}
</script>
